import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';

const version = 'v1';
export const RECUPERAR_USUARIOS_ENDPOINT = `/${version}/usuarios`;

export function useUsuariosService() {
  const { backendGet, backendPost, backendPut, backendDelete } =
    Sebrae.useBackend();

  const [userList] = React.useState([]);

  const getPermissoes = React.useCallback(
    () => backendGet(`${version}/usuarios`),
    [backendGet],
  );

  const getPermissoesId = React.useCallback(
    (id) => backendGet(`${version}/usuarios/${id}`),
    [backendGet],
  );

  const getPermissoesUsuarioAmei = React.useCallback(
    (cpfOuEmail) => backendGet(`${version}/usuarios/amei/${cpfOuEmail}`),
    [backendGet],
  );

  const getPermissoesUsuario = React.useCallback(
    (cpfOuEmail) =>
      backendGet(`${version}/usuarios/cpf-ou-email/${cpfOuEmail}`),
    [backendGet],
  );

  const getPerfis = React.useCallback(
    () => backendGet(`${version}/usuarios/perfis`),
    [backendGet],
  );

  const getUfs = React.useCallback(
    () => backendGet(`${version}/ufs`),
    [backendGet],
  );

  const postCadastrarUsuario = React.useCallback(
    (usuarioDTO) => backendPost(`${version}/usuarios`, usuarioDTO),
    [backendPost],
  );

  const putAutorizarUsuario = React.useCallback(
    (listaUsuarios) =>
      backendPut(`${version}/usuarios/autorizar`, [listaUsuarios]),
    [backendPut],
  );

  const getHistoricoUsuario = React.useCallback(
    (id) => backendGet(`${version}/usuarios/historico/${id}`),
    [backendGet],
  );

  const deletaPermissaoUsuario = React.useCallback(
    (id) => backendDelete(`${version}/usuarios/${id}`),
    [backendDelete],
  );

  return {
    userList,
    getPermissoes,
    getPermissoesId,
    getPerfis,
    getUfs,
    getPermissoesUsuarioAmei,
    getPermissoesUsuario,
    postCadastrarUsuario,
    putAutorizarUsuario,
    deletaPermissaoUsuario,
    getHistoricoUsuario,
    RECUPERAR_USUARIOS_ENDPOINT,
  };
}
