import { BaSeLoadingPage } from '@base/react';
import React, { createContext, useCallback, useState } from 'react';
import { useUsuariosService } from '../../hooks/usuarios-service';

export const AppContext = createContext(null);

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AppContextProvider = ({ children }) => {
  const getInitialUserList = () => {
    if (typeof window !== 'undefined') {
      const savedUserList = localStorage.getItem('userList');
      return savedUserList ? JSON.parse(savedUserList) : [];
    }
    return [];
  };

  const [userList, setUserList] = useState(getInitialUserList());
  const [roleMap, setRoleMap] = useState(() => {
    const savedCopiedUser =
      typeof window !== 'undefined' ? localStorage.getItem('copiedUser') : null;
    return {
      availableRoles: [],
      copiedUser: savedCopiedUser ? JSON.parse(savedCopiedUser) : null,
      disabledRoles: {},
      selectedRoles: {},
    };
  });

  const [isLoadingRoles, setIsLoadingRoles] = useState(true);
  const { getPerfis } = useUsuariosService();

  React.useEffect(() => {
    setIsLoadingRoles(true);
    getPerfis()
      .then((roles) => {
        console.log('Perfis recebidos em AppContextProvider:', roles);
        setRoleMap((prevRoleMap) => ({
          ...prevRoleMap,
          availableRoles: roles,
        }));
        setIsLoadingRoles(false);
      })
      .catch((error) => {
        console.error('Erro ao buscar perfis disponíveis:', error);
        setIsLoadingRoles(false);
      });
  }, [getPerfis]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('userList', JSON.stringify(userList));
    }
  }, [userList]);

  const addUserToList = useCallback((user) => {
    setUserList((prevList) => {
      if (!prevList.some((item) => item.id === user.id)) {
        return [...prevList, user];
      } else {
        console.warn('Usuário já existe na lista.');
        return prevList;
      }
    });
  }, []);

  const removeUserFromList = useCallback((userId) => {
    setUserList((prevList) => prevList.filter((user) => user.id !== userId));
  }, []);

  const clearUserList = useCallback(() => {
    setUserList([]);
    if (typeof window !== 'undefined') {
      localStorage.removeItem('userList');
    }
  }, []);

  const updateSelectedRoles = useCallback((roles) => {
    setRoleMap((prevRoleMap) => ({
      ...prevRoleMap,
      selectedRoles: roles,
    }));
  }, []);

  const copyUserRoles = useCallback((user) => {
    if (user) {
      const copiedUserData = {
        codPerfilKeycloak: user.codPerfilKeycloak,
        ifs: user.ifs || [],
        ufs: user.ufs || [],
      };
      setRoleMap((prevRoleMap) => ({
        ...prevRoleMap,
        copiedUser: copiedUserData,
      }));
      if (typeof window !== 'undefined') {
        localStorage.setItem('copiedUser', JSON.stringify(copiedUserData));
      }
    } else {
      console.error('Usuário inválido ou sem roles para copiar:', user);
    }
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const savedCopiedUser = localStorage.getItem('copiedUser');
      if (savedCopiedUser) {
        setRoleMap((prevRoleMap) => ({
          ...prevRoleMap,
          copiedUser: JSON.parse(savedCopiedUser),
        }));
      }
    }
  }, []);

  const pasteUserRoles = useCallback(() => {
    if (!roleMap.copiedUser) {
      console.warn('Tentativa de colar sem um usuário copiado.');
      return;
    }
    const newSelectedRoles = { ...roleMap.selectedRoles };

    if (roleMap.copiedUser.codPerfilKeycloak) {
      const role = roleMap.availableRoles.find(
        (r) => r.id === roleMap.copiedUser.codPerfilKeycloak,
      );
      if (role) {
        newSelectedRoles[role.nome] = true;
      }
    }
    roleMap.copiedUser.ifs.forEach((copiedIf) => {
      newSelectedRoles[`IF_${copiedIf.id}`] = true;
    });
    roleMap.copiedUser.ufs.forEach((copiedUf) => {
      newSelectedRoles[`UF_${copiedUf.id}`] = true;
    });
    roleMap.copiedUser.ifs.forEach((copiedIf) => {
      roleMap.copiedUser.ufs.forEach((copiedUf) => {
        newSelectedRoles[`IF_UF_${copiedIf.id}_${copiedUf.id}`] = true;
      });
    });

    setRoleMap((prevRoleMap) => ({
      ...prevRoleMap,
      selectedRoles: newSelectedRoles,
    }));
  }, [
    roleMap.copiedUser,
    roleMap.availableRoles,
    roleMap.selectedRoles,
    setRoleMap,
  ]);

  const clearCopiedUser = useCallback(() => {
    setRoleMap((prevRoleMap) => ({
      ...prevRoleMap,
      copiedUser: null,
    }));
    localStorage.removeItem('copiedUser');
  }, []);

  const contextValue = {
    roleMap,
    setRoleMap,
    addUserToList,
    removeUserFromList,
    clearUserList,
    setSelectedRoles: updateSelectedRoles,
    selectedRoles: roleMap.selectedRoles,
    disabledRoles: roleMap.disabledRoles,
    availableRoles: roleMap.availableRoles,
    userList,
    setUserList,
    ufs: roleMap.ufs,
    ifs: roleMap.ifs,
    copyUserRoles,
    pasteUserRoles,
    copiedUser: roleMap.copiedUser,
    clearCopiedUser,
    isLoadingRoles,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {isLoadingRoles ? <BaSeLoadingPage /> : children}
    </AppContext.Provider>
  );
};
