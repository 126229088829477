import {
  BaSeBackendNoSignalPage,
  BaSeDialogContainer,
  BaSeErrorPage,
  BaSeForbiddenPage,
  BaSeLoadingPage,
  BaSeNotFoundPage,
  BaSeRoot,
} from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { name as appName, version as appVersion } from '../package.json';
import { Routes } from './routes';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from './shared/contexts/AppContext';

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <BaSeRoot>
        <Sebrae.Root
          appName={appName}
          appVersion={appVersion}
          errorPage={(error) => <BaSeErrorPage error={error} />}
          loadingPage={() => <BaSeLoadingPage />}
          notFoundPage={() => <BaSeNotFoundPage />}
          forbiddenPage={() => <BaSeForbiddenPage />}
          backendNoSignalPage={() => <BaSeBackendNoSignalPage />}
        >
          <Routes />
          <BaSeDialogContainer />
        </Sebrae.Root>
      </BaSeRoot>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register();
