export const GESTAO_AMEI_PROP = '_gestaoAmei';
export const IF_GROUP = 'Instituição Financeira';
export const IF_GESTOR_UF_GROUP = 'Instituição Financeira do Gestor de UF';
export const UF_IF_GROUP = 'UF da Instituição Financeira';
export const UF_GROUP = 'UF';

export const Role = {
  GestorNacional: 'ROLE_SEBRAE_NA',
  GestorUF: 'ROLE_SEBRAE_UF',
  GestorIF: 'ROLE_IF',
};

export const getRoleByCode = (roleCode) => {
  const roleMap = {
    1: Role.GestorNacional,
    2: Role.GestorIF,
    3: Role.GestorUF,
  };
  return roleMap[roleCode];
};

export const ADMIN_ICON = 'shield-check';
