import { BaSeLoadingPage } from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';
import { Layout } from './components/layout';
import { Role } from './models/role';
import { HomePage } from './pages/home/home';
import BindClientIF from './pages/report/bind-client-if';

export const QUERY_STRING_EMBED = 'embed';
export const REALM_PARAM = 'realm';
export const TOKEN_PARAM = 'token';
export const CLIENT_PARAM = 'client';
export const USERNAME_PARAM = 'username';
export const USER_UUID_PARAM = 'userUUID';
export const DONT_GO_BACK_PARAM = 'dontGoBack';
export const ROLES_SUGGESTION_PARAM = 'rolesSuggestion';
export const USERS_SUGGESTION_PARAM = 'usersSuggestion';

const LazyProtectedPageWrapper = ({ children }) => (
  <React.Suspense fallback={<BaSeLoadingPage />}>
    <Layout>{children}</Layout>
  </React.Suspense>
);
const Client = React.lazy(() => import('./pages/report/client'));

const CompleteClienteRegistration = React.lazy(() =>
  import('./pages/report/complete-client-registrarion'),
);

const CreditOperation = React.lazy(() =>
  import('./pages/report/credit-operation'),
);

const EnforceabilityMpo = React.lazy(() =>
  import('./pages/report/enforceability-mpo'),
);

const SyntheticRelationship = React.lazy(() =>
  import('./pages/report/synthetic-relationship'),
);

const AnalitycalRelationship = React.lazy(() =>
  import('./pages/report/analytical-relationship'),
);

const ReportParmetrizeSolutions = React.lazy(() =>
  import('./pages/report/report-parametrize-solutions'),
);

const WaitingRoomPage = React.lazy(() =>
  import('./pages/waiting-room/waiting-room'),
);

const ParameterizeUfMail = React.lazy(() =>
  import('./pages/parameterize-uf-mail/parameterize-uf-mail'),
);

const InformProjectAction = React.lazy(() =>
  import('./pages/inform-project-action/inform-project-action'),
);

const ImportClientsFampe = React.lazy(() =>
  import('./pages/import-clients-fampe/import-clients-fampe'),
);

const ParametrizeSolutions = React.lazy(() =>
  import('./pages/parametrize-solutions/parametrize-solutions'),
);

const GroupSolutions = React.lazy(() =>
  import('./pages/report/group-solutions'),
);

const AutomaticRoutinesMonitoring = React.lazy(() =>
  import('./pages/automatic-routines-monitoring/automatic-routines-monitoring'),
);

const NewAccessProfile = React.lazy(() =>
  import('./pages/configure-access-profile/new-access-profile'),
);

const EditAccessProfile = React.lazy(() =>
  import('./pages/configure-access-profile/edit-access-profile'),
);

const ListConfigureAccessProfile = React.lazy(() =>
  import('./pages/configure-access-profile/list-configure-access-profile'),
);

const homePath = '/';

export const Routes = () => (
  <Sebrae.Routes
    routes={[
      {
        routePath: homePath,
        isExactPath: true,
        pageToRender: (
          <Layout>
            <HomePage />
          </Layout>
        ),
      },
      {
        routePath: '/sala-espera',
        isExactPath: true,
        ifIsAuthenticated: true,
        pageToRender: (
          <LazyProtectedPageWrapper>
            <WaitingRoomPage />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/cliente',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional, Role.GestorUF, Role.GestorIF],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <Client />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/cliente-cadastro-completo',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional, Role.GestorUF, Role.GestorIF],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <CompleteClienteRegistration />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/operacao-credito',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional, Role.GestorUF, Role.GestorIF],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <CreditOperation />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/exigibilidade-mpo',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional, Role.GestorUF, Role.GestorIF],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <EnforceabilityMpo />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/manutencao-relacionamento-analitico',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional, Role.GestorUF, Role.GestorIF],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <AnalitycalRelationship />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/manutencao-relacionamento-sintetico',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional, Role.GestorUF, Role.GestorIF],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <SyntheticRelationship />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/relatorio-solucoes-parametrizadas',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional, Role.GestorUF, Role.GestorIF],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <ReportParmetrizeSolutions />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/informar-projeto-acao',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <InformProjectAction />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/importar-clientes-fampe',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <ImportClientsFampe />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/configurar-perfil-acesso',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <ListConfigureAccessProfile />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/novo-perfil-acesso',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <NewAccessProfile />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/edit-perfil-acesso/:id',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <EditAccessProfile />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/parametrizar-uf-email',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <ParameterizeUfMail />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/parametrizar-solucoes',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <ParametrizeSolutions />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/agrupar-solucoes',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <GroupSolutions />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/vincular-cliente-if',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <BindClientIF />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
      {
        routePath: '/relatorio/automatic-routines-monitoring',
        isExactPath: true,
        ifHaveTheseRoles: [Role.GestorNacional],
        pageToRender: (
          <LazyProtectedPageWrapper>
            <AutomaticRoutinesMonitoring />
          </LazyProtectedPageWrapper>
        ),
        orRedirectTo: homePath,
      },
    ]}
  />
);
